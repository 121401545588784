.label-date-picker {
  margin-bottom: 4px;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}

.label-date-picker > span {
  margin: -.2em 0 0 .2em;
  content: '*';
  color: #db2828;
}

.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100%;
}

.form-header {
  margin-bottom: 3vw!important;
  margin-top: 3vw!important;
}

.ui.header {
  border: none;
  margin: calc(2rem - .14285714em) 0 1rem;
  padding: 0 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  font-weight: 700;
  line-height: 1.28571429em;
  text-transform: none;
  font-size: 14px!important;
  color: rgba(0,0,0,.87);
}


.ui.attached.info.message, .ui.info.message {
  -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
  margin-bottom: 3rem;
}
.ui.form .field>label, .label-date-picker {
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: 0.928571em;
  font-weight: 700;
  text-transform: none;
}
#transactionDate:focus {
  pointer-events: none;
}

.ui.mini.message {
  font-size: 0.785714em!important;
  line-height: 1.8!important;
}

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: none!important;
  width: 100%!important;
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 100%!important;
  vertical-align: middle;
}

.SingleDatePicker {
  position: relative;
  display: inline-block;
  width: 100%;
}
@media screen and (max-width: 768px) {
  input[type="date"]::before {
    content: attr(data-placeholder);
    width: 100%;
    color:  rgba(191,191,191,.87);
  }

  /* hide our custom/fake placeholder text when in focus to show the default
   * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
   */
  input[type="date"]:focus::before,
  input[type="date"]:valid::before { display: none }

  .ui.blue.button {
    -webkit-box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    box-shadow: 0 0 0 0 rgba(34,36,38,.15) inset;
    width: 100%;
    margin-bottom: 2rem;
  }
  .ui.attached.info.message, .ui.info.message {
    -webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    font-size: 11px;
    margin-bottom: 1rem;
  }
  .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: 0 0 0 0 transparent inset;
    -webkit-transition: color .1s ease,border-color .1s ease;
    -o-transition: color .1s ease,border-color .1s ease;
    transition: color .1s ease,border-color .1s ease;
    height: 38px;
  }

}
