body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.no-param-error-message {
  position: absolute!important;
  top: 50%!important;
  transform: translate(-50%, -50%)!important;
  left: 50%!important;
  width: 50vw;
}


@media screen and (max-width: 768px) {
  .no-param-error-message {
    width: 80vw;
  }


}
